import React from "react";

function BGImages({ image1, image2, image3 }) {
  return (
    <div className="flex flex-col gap-8 sm:gap-12 md:gap-16 lg:gap-20">
      {[
        { src: image1, alt: "Project Overview" },
        { src: image2, alt: "Project Details" },
        { src: image3, alt: "Project Results" },
      ].map((image, index) => (
        <div
          key={index}
          className="w-full max-w-[1000px] mx-auto aspect-[1000/636] rounded-lg overflow-hidden bg-white shadow-lg"
        >
          <img
            src={image.src}
            alt={image.alt}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  );
}

export default BGImages;
