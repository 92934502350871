import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Work from "./Pages/Work";
import Contact from "./Pages/Contact";
import Project1Details from "./Pages/Project1Details";
import Project2Details from "./Pages/Project2Details";
import Project3Details from "./Pages/Project3Details";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/project-1acre.in" element={<Project1Details />} />
        <Route path="/project-smgroup" element={<Project2Details />} />
        <Route path="/project-salonbooking" element={<Project3Details />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
