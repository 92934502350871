import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import SocialIcons from "../components/SocialIcons";
import HeroTitle from "../components/HeroTitle";
import AboutSection from "../components/AboutSection";
import Button from "../components/Button";
import Project from "../components/Project";
import LogoDesigns from "../components/LogoDesigns";
import "../index.css";
import GraphicDesigns from "../components/GraphicDesigns";
import { HiArrowLongRight, HiArrowLongLeft } from "react-icons/hi2";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import NikePoster from "../assets/images/NikePoster.webp";
import NikeFlyer from "../assets/images/NikeFlyer.webp";
import NikeManipulation from "../assets/images/NikeManipulation.webp";
import zealAmpere from "../assets/images/zealAmpere.svg";
import Bullet from "../assets/images/Bullet.webp";
import magnuspro from "../assets/images/magnuspro.webp";
import optima from "../assets/images/optima.webp";
import stitchhub1 from "../assets/images/stitchhub1.svg";
import stitchhub4 from "../assets/images/stitchhub4.svg";
import Background1 from "../assets/images/Background1.webp";
import Space from "../assets/images/space.svg";
import MiraiGen from "../assets/images/Mirai Gen 1.svg";
import Space2 from "../assets/images/space2.svg";
import IAMDigipro from "../assets/images/IAM Digipro 1.svg";
import leaves from "../assets/images/leaves.webp";
import SavitrisKitchen from "../assets/images/Savitris Kitchen 1.svg";
import Beach from "../assets/images/beach.webp";
import ClyminAI from "../assets/images/Clymin.ai 2.svg";
import Charminar from "../assets/images/charminar.webp";
import AutoUncle from "../assets/images/AutoUncle 1.svg";
import hairstyle from "../assets/images/hairstyle.webp";
import TopStaars1 from "../assets/images/TopStaars1.svg";
import democracy from "../assets/images/democracy.webp";
import DemocracyLogo from "../assets/images/Democracy Logo new variant 1.svg";
import Project1 from "../assets/images/Project1.webp";
import Project2 from "../assets/images/Project2.webp";
import Project3 from "../assets/images/Project3.webp";

function Home() {
  const logos = [
    { bg: Space, overlay: MiraiGen, brightness: true },
    { bg: Space2, overlay: IAMDigipro, brightness: true },
    { bg: leaves, overlay: SavitrisKitchen, brightness: false },
    { bg: Beach, overlay: ClyminAI, brightness: false },
    { bg: Charminar, overlay: AutoUncle, brightness: true },
    { bg: hairstyle, overlay: TopStaars1, brightness: false },
    { bg: democracy, overlay: DemocracyLogo, brightness: true },
  ];

  const designs = [
    NikePoster,
    NikeFlyer,
    NikeManipulation,
    zealAmpere,
    Bullet,
    magnuspro,
    optima,
    stitchhub1,
    stitchhub4,
  ];

  const [currentSet, setCurrentSet] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const designSets = [
    [0, 1, 2],
    [1, 2, 3],
    [2, 3, 4],
    [3, 4, 5],
    [4, 5, 6],
    [5, 6, 7],
    [6, 7, 8],
    [7, 8, 9],
    [8, 9, 10],
  ];

  const nextDesign = () => {
    if (currentSet < designSets.length - 1 && !isAnimating) {
      setIsAnimating(true);
      setCurrentSet(currentSet + 1);
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  const prevDesign = () => {
    if (currentSet > 0 && !isAnimating) {
      setIsAnimating(true);
      setCurrentSet(currentSet - 1);
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  return (
    <div className="relative w-full h-screen overflow-hidden border-8 border-black">
      <Helmet>
        <title>Design Developer – Home</title>
        <meta
          name="description"
          content="Welcome to Design Developer. We design, develop, and innovate to create high-quality, user-centered digital products."
        />
      </Helmet>
      <div
        className="fixed inset-0 bg-cover bg-center brightness-50 z-[1]"
        style={{
          clipPath: "inset(8px)",
          backgroundImage: `url(${Background1})`,
        }}
      />
      <div className="absolute inset-0 border border-zinc-500 rounded-lg z-[999] pointer-events-none" />
      <div className="relative z-[2] overflow-y-auto h-screen scrollbar-custom">
        <div className="sticky top-0 z-[3]">
          <Navbar />
        </div>

        <div className="relative z-[3]">
          <SocialIcons />
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="pt-10 pb-14 flex justify-center items-center h-[80vh]">
            <HeroTitle />
          </div>

          <div className="flex flex-col justify-center items-center gap-14 max-w-full overflow-hidden pt-14 pb-14 pl-2.5 pr-2.5">
            <AboutSection />
            <Button name="About-me" className="relative" path="/about" />
          </div>
          <div className="relative flex flex-col gap-14 justify-center items-center pt-14 pb-14 max-w-[1012px]">
            <span className="font-sora font-medium text-[#80FD76] text-2xl">
              Figma Designs Highlight
            </span>
            <div className="flex flex-wrap justify-center items-center gap-4 w-full pl-2.5 pr-2.5 ">
              <Project
                title="1acre.in"
                link="/project-1acre.in"
                imageUrl={Project1}
              />
              <Project
                title="SM Group Developers & Constructions"
                link="/project-smgroup"
                imageUrl={Project2}
              />
              <Project
                title="Salon Slot Booking & Scheduling"
                link="/project-salonbooking"
                imageUrl={Project3}
              />
            </div>
            <Button name="Explore-all" className="relative" path="/work" />
          </div>

          <div className="relative flex flex-col gap-14 justify-center items-center pt-14 pb-14 w-full px-4 sm:px-6 lg:px-8">
            <span className="font-sora font-medium text-[#80FD76] text-xl sm:text-2xl text-center">
              My Best Graphic and Logo Designs
            </span>

            <div className="logo-wrapper w-full max-w-[1000px] overflow-hidden">
              <div className="relative">
                <div className="flex flex-nowrap gap-4 animate-scroll hover:pause">
                  <div className="flex flex-nowrap gap-4">
                    {logos.map((logo, index) => (
                      <LogoDesigns
                        key={`first-${index}`}
                        backgroundImage={logo.bg}
                        overlayImage={logo.overlay}
                        applyBrightness={logo.brightness}
                      />
                    ))}
                  </div>
                  <div className="flex flex-nowrap gap-4">
                    {logos.map((logo, index) => (
                      <LogoDesigns
                        key={`second-${index}`}
                        backgroundImage={logo.bg}
                        overlayImage={logo.overlay}
                        applyBrightness={logo.brightness}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center gap-6 w-full max-w-[980px] overflow-hidden">
            <div className="relative w-full">
              <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{
                  transform: `translateX(-${currentSet * (980 / 3)}px)`,
                }}
              >
                {designs.map((design, index) => (
                  <div
                    key={index}
                    className="w-[calc(980px/3 - 8px)] flex-shrink-0 mx-1"
                  >
                    <GraphicDesigns backgroundImage={design} />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex self-end gap-3">
              <button
                className={`self-start transition-opacity duration-200 ${
                  currentSet === 0 || isAnimating
                    ? "opacity-50 cursor-allowed"
                    : "opacity-100 cursor-pointer hover:opacity-80"
                }`}
                onClick={prevDesign}
                disabled={currentSet === 0 || isAnimating}
              >
                <HiArrowLongLeft
                  className="text-white"
                  style={{
                    fontSize: "35px",
                    minWidth: "30px",
                    minHeight: "20px",
                  }}
                />
              </button>
              <button
                className={`self-end transition-opacity duration-200 ${
                  currentSet === designSets.length - 1 || isAnimating
                    ? "opacity-50 cursor-allowed"
                    : "opacity-100 cursor-pointer hover:opacity-80"
                }`}
                onClick={nextDesign}
                disabled={currentSet === designSets.length - 1 || isAnimating}
              >
                <HiArrowLongRight
                  className="text-white"
                  style={{
                    fontSize: "35px",
                    minWidth: "30px",
                    minHeight: "20px",
                  }}
                />
              </button>
            </div>
          </div>
          <ContactForm />
        </div>
        <div
          className="w-[99.8%] pt-14"
          style={{
            paddingBottom: "17px",
          }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
