import React from "react";
import { Helmet } from "react-helmet";
import Background7 from "../assets/images/Background7.webp";
import Navbar from "../components/Navbar";
import SocialIcons from "../components/SocialIcons";
import "../index.css";
import Footer from "../components/Footer";
import ContactForm1 from "../components/ContactForm1";

function Contact() {
  return (
    <div className="relative w-full h-screen overflow-hidden border-8 border-black">
      <Helmet>
        <title>Design Developer – Contact</title>
        <meta
          name="description"
          content="Welcome to Design Developer. We design, develop, and innovate to create high-quality, user-centered digital products."
        />
      </Helmet>
      <div
        className="fixed inset-0 bg-cover bg-center brightness-50 z-[1]"
        style={{
          clipPath: "inset(8px)",
          backgroundImage: `url(${Background7})`,
        }}
      />
      <div className="absolute inset-0 border border-zinc-500 rounded-lg z-[999] pointer-events-none" />
      <div className="relative z-[2] overflow-y-auto h-screen scrollbar-custom">
        <div className="sticky top-0 z-[3]">
          <Navbar />
        </div>

        <div className="relative z-[3]">
          <SocialIcons />
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="relative flex flex-col gap-6 justify-center items-start pt-4 pb-14 max-w-[1000px]">
            <span
              className="font-sora font-medium text-[#80FD76] text-xl sm:text-2xl text-left pl-4 pr-4"
            >
              Reach out & let's collaborate
              <br />
              on something great
            </span>
            <ContactForm1 />
          </div>
          <div
            className="w-[99.8%] pt-14"
            style={{
              paddingBottom: "17px",
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
