import React from "react";
import { Helmet } from "react-helmet";
import Background5 from "../assets/images/Background5.webp";
import Navbar from "../components/Navbar";
import SocialIcons from "../components/SocialIcons";
import "../index.css";
import Project from "../components/Project";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Project1 from "../assets/images/Project1.webp";
import Project2 from "../assets/images/Project2.webp";
import Project3 from "../assets/images/Project3.webp";

function Work() {
  return (
    <div className="relative w-full h-screen overflow-hidden border-8 border-black">
      <Helmet>
        <title>Design Developer – Work</title>
        <meta
          name="description"
          content="Welcome to Design Developer. We design, develop, and innovate to create high-quality, user-centered digital products."
        />
      </Helmet>
      <div
        className="fixed inset-0 bg-cover bg-center brightness-50 z-[1]"
        style={{
          clipPath: "inset(8px)",
          backgroundImage: `url(${Background5})`,
        }}
      />
      <div className="absolute inset-0 border border-zinc-500 rounded-lg z-[999] pointer-events-none" />
      <div className="relative z-[2] overflow-y-auto h-screen scrollbar-custom">
        <div className="sticky top-0 z-[3]">
          <Navbar />
        </div>

        <div className="relative z-[3]">
          <SocialIcons />
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="relative flex flex-col gap-14 justify-center items-center pt-8 pb-14 max-w-[1012px]">
            <span
              className="font-sora font-medium text-[#80FD76] text-xl sm:text-2xl text-center"
            >
              A collection of my projects
            </span>
            <div className="flex flex-wrap justify-center items-center gap-4 w-full pl-2.5 pr-2.5">
              <Project
                title="1acrein"
                link="/project-1acre.in"
                imageUrl={Project1}
              />
              <Project
                title="SM Group Developers & Constructions"
                link="/project-smgroup"
                imageUrl={Project2}
              />
              <Project
                title="Salon Slot Booking & Scheduling"
                link="/project-salonbooking"
                imageUrl={Project3}
              />
            </div>
          </div>
          <ContactForm />
          <div
            className="w-[99.8%] pt-14"
            style={{
              paddingBottom: "17px",
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
