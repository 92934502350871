import React from "react";

const LogoDesigns = React.memo(
  ({ backgroundImage, overlayImage, applyBrightness = false }) => {
    return (
      <div className="relative w-[260px] sm:w-[220px] md:w-[240px] lg:w-[260px] h-[150px] rounded-lg flex justify-center items-center overflow-hidden flex-none">
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: applyBrightness ? "brightness(0.5)" : "none",
            zIndex: "-1",
          }}
        />
        <div className="flex justify-center items-center relative z-10">
          <img
            src={overlayImage}
            alt="Overlay Image"
            className="w-3/5 h-auto object-contain"
            loading="lazy"
          />
        </div>
      </div>
    );
  }
);

export default LogoDesigns;
