import React, { useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Button = ({ name, path = " " }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleClick = () => {
    navigate(path);
  };

  return (
    <div
      className="inline-flex justify-center items-center gap-2 px-3.5 py-3.5 border border-[#80FD76] rounded-lg backdrop-blur-sm cursor-pointer overflow-hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="relative inline-flex items-center">
        <span
          className="absolute font-sora text-white text-xs whitespace-nowrap"
          style={{
            transform: isHovered ? "translateY(-150%)" : "translateY(0)",
            opacity: isHovered ? 0 : 1,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        >
          {name}
        </span>
        <span className="invisible font-sora text-white text-xs whitespace-nowrap">
          {name}
        </span>
        <span
          className="absolute font-sora text-white text-xs whitespace-nowrap"
          style={{
            transform: isHovered ? "translateY(0)" : "translateY(150%)",
            opacity: isHovered ? 1 : 0,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        >
          {name}
        </span>
      </div>

      <div className="relative inline-flex items-center">
        <IoArrowForward
          className="absolute text-white"
          size={16}
          style={{
            transform: isHovered ? "translateY(-150%)" : "translateY(0)",
            opacity: isHovered ? 0 : 1,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        />
        <IoArrowForward className="invisible" size={16} />
        <IoArrowForward
          className="absolute text-white"
          size={16}
          style={{
            transform: isHovered ? "translateY(0)" : "translateY(150%)",
            opacity: isHovered ? 1 : 0,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        />
      </div>
    </div>
  );
};

export default Button;
