import React, { useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import { sendContactForm } from "../services/emailService";

const AnimatedButton = ({ name, onClick, disabled, isSubmitting }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => !disabled && setIsHovered(true);
  const handleMouseLeave = () => !disabled && setIsHovered(false);

  return (
    <button
      type="submit"
      disabled={disabled || isSubmitting}
      className={`inline-flex justify-center items-center gap-2 px-3.5 py-3.5 border rounded-lg backdrop-blur-sm overflow-hidden
        ${disabled || isSubmitting 
          ? 'border-gray-400 cursor-not-allowed opacity-60' 
          : 'border-[#80FD76] cursor-pointer'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <div className="relative inline-flex items-center">
        <span
          className="absolute font-sora text-white text-xs whitespace-nowrap"
          style={{
            transform: isHovered ? "translateY(-150%)" : "translateY(0)",
            opacity: isHovered ? 0 : 1,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        >
          {isSubmitting ? "Sending..." : name}
        </span>
        <span className="invisible font-sora text-white text-xs whitespace-nowrap">
          {isSubmitting ? "Sending..." : name}
        </span>
        <span
          className="absolute font-sora text-white text-xs whitespace-nowrap"
          style={{
            transform: isHovered ? "translateY(0)" : "translateY(150%)",
            opacity: isHovered ? 1 : 0,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        >
          {isSubmitting ? "Sending..." : name}
        </span>
      </div>

      <div className="relative inline-flex items-center">
        <IoArrowForward
          className="absolute text-white"
          size={16}
          style={{
            transform: isHovered ? "translateY(-150%)" : "translateY(0)",
            opacity: isHovered ? 0 : 1,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        />
        <IoArrowForward className="invisible" size={16} />
        <IoArrowForward
          className="absolute text-white"
          size={16}
          style={{
            transform: isHovered ? "translateY(0)" : "translateY(150%)",
            opacity: isHovered ? 1 : 0,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        />
      </div>
    </button>
  );
};

function ContactForm1() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    website: "",
    message: ""
  });
  
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full name is required";
    }
    
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    
    if (!termsAccepted) {
      newErrors.terms = "Please accept the terms to continue";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ type: "", message: "" });
    
    if (!validateForm()) {
      setSubmitStatus({
        type: "error",
        message: "Please fill in all required fields and accept the terms"
      });
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const response = await sendContactForm(formData);
      setSubmitStatus({
        type: "success",
        message: "Message sent successfully! We'll get back to you soon."
      });
      
      setFormData({
        fullName: "",
        email: "",
        website: "",
        message: ""
      });
      setTermsAccepted(false);
      
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send message. Please try again later."
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ""
      }));
    }
  };

  const inputBaseClass = "p-4 border rounded-lg backdrop-blur bg-transparent text-[#80FD76] font-sora placeholder:text-[#999999] placeholder:font-sora placeholder:text-sm";

  return (
    <div className="w-full flex justify-center items-center min-h-screen">
      <form 
        onSubmit={handleSubmit} 
        className="w-[95%] sm:w-[550px] flex flex-col gap-6 justify-center items-center border border-zinc-500 rounded-lg relative p-6 sm:p-9 backdrop-blur-sm"
        style={{
          maxWidth: "550px",
          margin: "0 auto"
        }}
      >
        {submitStatus.message && (
          <div className={`w-full sm:w-[450px] p-4 rounded-lg ${
            submitStatus.type === "error" 
              ? "bg-red-100 text-red-800 border border-red-300" 
              : "bg-green-100 text-green-800 border border-green-300"
          }`}>
            {submitStatus.message}
          </div>
        )}
        
        <div className="w-full sm:w-[450px] space-y-1">
          <label className="font-sora font-light text-white text-sm">
            Full Name*
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Enter your full name"
            className={`${inputBaseClass} w-full h-[55px] ${
              errors.fullName ? "border-red-500" : "border-zinc-500"
            }`}
          />
          {errors.fullName && (
            <span className="text-red-500 text-xs">{errors.fullName}</span>
          )}
        </div>

        <div className="w-full sm:w-[450px] space-y-1">
          <label className="font-sora font-light text-white text-sm">
            Email*
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            className={`${inputBaseClass} w-full h-[55px] ${
              errors.email ? "border-red-500" : "border-zinc-500"
            }`}
          />
          {errors.email && (
            <span className="text-red-500 text-xs">{errors.email}</span>
          )}
        </div>

        <div className="w-full sm:w-[450px] space-y-1">
          <label className="font-sora font-light text-white text-sm">
            Website
          </label>
          <input
            type="url"
            name="website"
            value={formData.website}
            onChange={handleChange}
            placeholder="Enter your website (optional)"
            className={`${inputBaseClass} w-full h-[55px] border-zinc-500`}
          />
        </div>

        <div className="w-full sm:w-[450px] space-y-1">
          <label className="font-sora font-light text-white text-sm">
            Your Message*
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Type your message here"
            className={`${inputBaseClass} w-full h-[140px] resize-none ${
              errors.message ? "border-red-500" : "border-zinc-500"
            }`}
          />
          {errors.message && (
            <span className="text-red-500 text-xs">{errors.message}</span>
          )}
        </div>

        <div className="w-full sm:w-[450px] flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 pt-5">
          <div className="flex items-start gap-2.5">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className="w-6 h-6 border-2 border-zinc-500 rounded-md bg-transparent 
                checked:bg-[#7ffd76b6] checked:border-[#7ffd76b6] focus:ring-[#7ffd76b6]
                mt-1 sm:mt-0"
            />
            <span className="font-sora font-light text-white text-xs">
              I agree with the terms of the<br />privacy policy
            </span>
          </div>
          {errors.terms && (
            <span className="text-red-500 text-xs self-start sm:self-center">{errors.terms}</span>
          )}
          <AnimatedButton 
            name="Submit-message"
            disabled={!termsAccepted}
            isSubmitting={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}

export default ContactForm1;