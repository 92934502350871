import React from "react";
import Button from "../components/Button";

function ProjectDetails({ title, description }) {
  return (
      <div className="flex flex-col md:flex-row justify-between items-start md:items-end w-full max-w-[1000px] border border-zinc-500 rounded-lg relative p-4 md:p-6 lg:p-8 backdrop-blur-sm overflow-hidden">
      <div className="w-full md:w-2/3 lg:w-3/4 flex flex-col gap-3 mb-4 md:mb-0">
        <h2 className="font-sora font-medium text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
          {title}
        </h2>
        <p className="font-sora font-light text-white text-xs sm:text-sm md:text-base">
          {description}
        </p>
      </div>
      <div className="flex flex-row md:flex-col items-end justify-between w-[280px] md:items-end gap-4">
        <div className="flex flex-row md:flex-col gap-2">
          <span className="inline-block bg-[#4e754c] text-white text-xs font-sora font-light px-2 py-1 rounded-full">
            figma
          </span>
          <span className="inline-block border border-zinc-600 text-white text-xs font-sora font-light px-2 py-1 rounded-full">
            2024
          </span>
        </div>
        <Button name="Visit-Website" className="text-sm md:text-base" />
      </div>
    </div>
  );
}

export default ProjectDetails;
