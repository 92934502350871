import React, { useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Navbar2 = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="sticky top-0 left-0 right-0 z-10 w-full">
      <div className="flex justify-between items-center px-4 sm:px-8 md:px-14 py-4 backdrop-blur-sm">
        <button
          className="group relative overflow-hidden p-2 border border-zinc-500 rounded-lg hover:bg-zinc-800/30 transition-colors duration-300 cursor-pointer"
          onClick={() => navigate("/work")}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="h-[18px] w-[18px]">
            {/* Top Arrow (initially visible) */}
            <div
              className={`transform transition-all duration-300 ease-in-out absolute`}
              style={{
                transform: isHovered ? 'translateY(-100%)' : 'translateY(0)',
                opacity: isHovered ? 0 : 1
              }}
            >
              <IoArrowBackOutline
                className="text-white"
                style={{
                  fontSize: "18px",
                }}
              />
            </div>

            {/* Bottom Arrow (initially hidden) */}
            <div
              className={`transform transition-all duration-300 ease-in-out absolute`}
              style={{
                transform: isHovered ? 'translateY(0)' : 'translateY(100%)',
                opacity: isHovered ? 1 : 0
              }}
            >
              <IoArrowBackOutline
                className="text-white"
                style={{
                  fontSize: "18px",
                }}
              />
            </div>
          </div>
        </button>
      </div>
    </nav>
  );
};

export default Navbar2;