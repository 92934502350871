import React from "react";
import Navbar2 from "../components/Navbar2";
import SocialIcons from "../components/SocialIcons";
import Background6 from "../assets/images/Background6.webp";
import ProjectDetails from "../components/PeojectDetails";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import BGImages from "../components/BGImages";
import Project1Background1 from "../assets/images/Project1Background1.webp";
import Project1Background2 from "../assets/images/Project1Background2.webp";
import Project1Background3 from "../assets/images/Project1Background3.webp";

function Project1Details() {
  return (
    <div className="relative w-full h-screen overflow-hidden border-8 border-black">
      <div
        className="fixed inset-0 bg-cover bg-center brightness-[50%] z-[1]"
        style={{
          clipPath: "inset(8px)",
          backgroundImage: `url(${Background6})`,
        }}
      />

      <div className="absolute inset-0 border border-zinc-500 rounded-lg z-[999] pointer-events-none" />

      <div className="relative z-[2] h-screen overflow-y-auto scrollbar-custom">
        <div className="sticky top-0 z-[3]">
          <Navbar2 />
        </div>
        <div className="relative z-[3]">
          <SocialIcons />
        </div>
        <div className="flex flex-col gap-20 justify-center items-center px-3">
          <ProjectDetails
            title="1acre.in"
            description="As a UI Designer at 1acre.in, I elevated online user experiences by creating user-friendly and intuitive interfaces. I designed engaging UI elements, leveraged problem-solving to enhance user interactions, and collaborated with cross-functional teams to align design with business objectives and user needs. My contributions led to notable improvements in platform usability and user satisfaction."
          />

          <BGImages
            image1={Project1Background3}
            image2={Project1Background2}
            image3={Project1Background1}
          />

          <ContactForm />
        </div>

        {/* Footer */}
        <div className="w-[99.8%] pt-14 pb-[17px]">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Project1Details;
