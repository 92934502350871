import React from "react";

function SocialIcons() {
  return (
    <div className="fixed z-50 bottom-10 right-4 lg:right-16 hidden md:flex gap-3 m-0">
      <a
        href="https://www.linkedin.com/in/madhusudhanreddy-k/"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:opacity-80 transition-opacity"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 2.219C0 1.2695 0.789 0.5 1.7625 0.5H22.2375C23.211 0.5 24 1.2695 24 2.219V22.781C24 23.7305 23.211 24.5 22.2375 24.5H1.7625C0.789 24.5 0 23.7305 0 22.781V2.219ZM7.4145 20.591V9.7535H3.813V20.591H7.4145ZM5.6145 8.273C6.87 8.273 7.6515 7.442 7.6515 6.401C7.629 5.3375 6.8715 4.529 5.6385 4.529C4.4055 4.529 3.6 5.339 3.6 6.401C3.6 7.442 4.3815 8.273 5.5905 8.273H5.6145ZM12.9765 20.591V14.5385C12.9765 14.2145 13.0005 13.8905 13.0965 13.6595C13.356 13.013 13.9485 12.3425 14.9445 12.3425C16.248 12.3425 16.7685 13.3355 16.7685 14.7935V20.591H20.37V14.375C20.37 11.045 18.594 9.497 16.224 9.497C14.313 9.497 13.4565 10.547 12.9765 11.2865V11.324H12.9525L12.9765 11.2865V9.7535H9.3765C9.4215 10.7705 9.3765 20.591 9.3765 20.591H12.9765Z"
            fill="white"
          />
        </svg>
      </a>

      <a
        href="https://github.com/designdeveloper5"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:opacity-80 transition-opacity"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0.800049C5.37 0.800049 0 6.17005 0 12.8C0 18.11 3.435 22.595 8.205 24.185C8.805 24.29 9.03 23.93 9.03 23.615C9.03 23.33 9.015 22.385 9.015 21.38C6 21.935 5.22 20.645 4.98 19.97C4.845 19.625 4.26 18.56 3.75 18.275C3.33 18.05 2.73 17.495 3.735 17.48C4.68 17.465 5.355 18.35 5.58 18.71C6.66 20.525 8.385 20.015 9.075 19.7C9.18 18.92 9.495 18.395 9.84 18.095C7.17 17.795 4.38 16.76 4.38 12.17C4.38 10.865 4.845 9.78505 5.61 8.94505C5.49 8.64505 5.07 7.41505 5.73 5.76505C5.73 5.76505 6.735 5.45005 9.03 6.99505C9.99 6.72505 11.01 6.59005 12.03 6.59005C13.05 6.59005 14.07 6.72505 15.03 6.99505C17.325 5.43505 18.33 5.76505 18.33 5.76505C18.99 7.41505 18.57 8.64505 18.45 8.94505C19.215 9.78505 19.68 10.85 19.68 12.17C19.68 16.775 16.875 17.795 14.205 18.095C14.64 18.47 15.015 19.19 15.015 20.315C15.015 21.92 15 23.21 15 23.615C15 23.93 15.225 24.305 15.825 24.185C18.2076 23.3814 20.278 21.8505 21.7446 19.8081C23.2112 17.7656 24 15.3145 24 12.8C24 6.17005 18.63 0.800049 12 0.800049Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  );
}

export default SocialIcons;
