import React, { useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";

function Project({ title, link, imageUrl }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to={link}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative flex justify-between items-end w-[320px] min-h-[280px] rounded-lg backdrop-blur-sm overflow-hidden cursor-pointer p-6">
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />
        <div
          className="absolute inset-0 z-10"
          style={{
            background: `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 70%)`,
            transition: "opacity 0.3s ease-in",
            opacity: isHovered ? 1 : 0,
          }}
        />
        <div
          className={`relative flex justify-center items-center z-10 w-full h-full transition-opacity duration-300 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex justify-between items-end w-full">
            <div
              className="font-sora font-light text-white"
              style={{
                fontSize: "clamp(14px, 6vw, 14px)",
                textAlign: "left",
                lineHeight: "20px",
                paddingTop: "0",
                paddingBottom: "0",
                marginTop: "0",
                marginBottom: "0",
              }}
            >
              <span>{title}</span>
            </div>
            <IoArrowForward
              className="text-white"
              style={{
                fontSize: "20px",
                minWidth: "20px",
                minHeight: "20px",
                transform: isHovered ? "rotate(-45deg)" : "rotate(0deg)",
                transition: "0.3s ease-in",
              }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Project;
