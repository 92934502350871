import React from "react";
import Navbar2 from "../components/Navbar2";
import SocialIcons from "../components/SocialIcons";
import Background8 from "../assets/images/Background8.webp";
import ProjectDetails from "../components/PeojectDetails";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import BGImages from "../components/BGImages";
import Project3Background1 from "../assets/images/Project3Background1.webp";
import Project3Background2 from "../assets/images/Project3Background2.webp";
import Project3Background3 from "../assets/images/Project3Background3.webp";

function Project3Details() {
  return (
    <div className="relative w-full h-screen overflow-hidden border-8 border-black">
      <div
        className="fixed inset-0 bg-cover bg-center brightness-[50%] z-[1]"
        style={{
          clipPath: "inset(8px)",
          backgroundImage: `url(${Background8})`,
        }}
      />

      <div className="absolute inset-0 border border-zinc-500 rounded-lg z-[999] pointer-events-none" />

      <div className="relative z-[2] h-screen overflow-y-auto scrollbar-custom">
        <div className="sticky top-0 z-[3]">
          <Navbar2 />
        </div>
        <div className="relative z-[3]">
          <SocialIcons />
        </div>

        <div className="flex flex-col gap-20 justify-center items-center pl-3 pr-3">
          <ProjectDetails
            title="Salon Booking"
            description="For the Salon Slot Booking Platform, I served as both a UI and UX Designer, focusing on crafting a seamless booking experience for users and a comprehensive dashboard for salon owners to manage appointments. I designed an intuitive, user-friendly interface that simplifies slot selection and booking, while the owner dashboard provides clear insights and controls for managing bookings and schedules. My work emphasized usability, ease of navigation, and accessibility, resulting in a polished, efficient platform that meets the needs of both users and salon owners."
          />
          <BGImages
            image1={Project3Background3}
            image2={Project3Background2}
            image3={Project3Background1}
          />
          <ContactForm />
        </div>
        <div
          className="w-[99.8%] pt-14"
          style={{
            paddingBottom: "17px",
          }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Project3Details;
