import React from "react";
import htmlLogo from "../assets/images/htmlLogo.svg";
import cssLogo from "../assets/images/cssLogo.svg";
import reactLogo from "../assets/images/reactLogo.svg";
import Tailwindcss from "../assets/images/Tailwindcss.svg";
import Figma from "../assets/images/Figma.svg";
import Photoshop from "../assets/images/Photoshop.svg";
import Illustrator from "../assets/images/Illustrator.svg";

function Technologies() {
  return (
    <div className="flex flex-col gap-8 border border-zinc-500 rounded-lg relative px-6 md:px-9 py-6 md:py-8 backdrop-blur-sm max-w-full overflow-hidden">
      {/* Technologies Section */}
      <div className="flex flex-col flex-wrap justify-center items-start gap-4 md:gap-6 w-full">
        <div
          className="font-sora font-semibold text-[#80FD76]"
          style={{
            fontSize: "16px",
          }}
        >
          Technologies I like to work with
        </div>
        {/* Tech Logos */}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 md:gap-10">
          <div className="flex justify-start items-center gap-3">
            <img src={htmlLogo} alt="HTML" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              HTML
            </div>
          </div>
          <div className="flex justify-start items-center gap-3">
            <img src={cssLogo} alt="CSS" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              CSS
            </div>
          </div>
          <div className="flex justify-start items-center gap-3">
            <img src={reactLogo} alt="React" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              React JS
            </div>
          </div>
          <div className="flex justify-start items-center gap-3">
            <img src={Tailwindcss} alt="Tailwind CSS" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              Tailwind CSS
            </div>
          </div>
        </div>
      </div>

      {/* Design Tools Section */}
      <div className="flex flex-col flex-wrap justify-center items-start gap-5 md:gap-6">
        <div
          className="font-sora font-semibold text-[#80FD76]"
          style={{
            fontSize: "16px",
          }}
        >
          Design tools I rely on
        </div>
        {/* Design Logos */}
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 md:gap-10">
          <div className="flex justify-start items-center gap-3">
            <img src={Figma} alt="Figma" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              Figma
            </div>
          </div>
          <div className="flex justify-start items-center gap-3">
            <img src={Photoshop} alt="Photoshop" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              Photoshop
            </div>
          </div>
          <div className="flex justify-start items-center gap-3">
            <img src={Illustrator} alt="Illustrator" />
            <div
              className="font-sora font-medium text-white"
              style={{
                fontSize: "15px",
              }}
            >
              Illustrator
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Technologies;
