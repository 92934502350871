import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import Logo from "../assets/images/Logo.svg";

function Navbar({}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Work", path: "/work" },
    { name: "Contact", path: "/contact" },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const index = menuItems.findIndex((item) => item.path === currentPath);
    setActiveIndex(index !== -1 ? index : 0);
  }, [location.pathname]);


  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);


  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [menuOpen]);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    const currentPath = location.pathname;
    const index = menuItems.findIndex((item) => item.path === currentPath);
    setActiveIndex(index !== -1 ? index : 0);
  };

  const handleClick = (index, path) => {
    setActiveIndex(index);
    navigate(path);
    setMenuOpen(false);
  };

  return (
    <nav className="sticky top-0 left-0 right-0 z-50 bg-[#00000065]">
      <div className="relative px-4 sm:px-6 md:px-14 py-3 flex justify-between items-center">
        <div className="font-sora font-semibold text-lg tracking-normal leading-6 text-white cursor-pointer z-50">
          <Link to="/" className="">
            <img
              src={Logo}
              alt="Logo"
              className="h-7 w-auto"
            />
          </Link>
        </div>

        <button
          className="md:hidden text-white z-50"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <IoClose className="text-2xl" />
          ) : (
            <IoMenu className="text-2xl" />
          )}
        </button>

        {/* Desktop Menu */}
        <div
          className="hidden md:flex items-center justify-center rounded-full backdrop-blur-sm"
          style={{
            backgroundColor: "rgb(128, 253, 118, 0.05)",
            width: "310px",
            height: "44px",
          }}
        >
          {menuItems.map((item, index) => (
            <div
              key={index}
              className="relative overflow-hidden flex items-center justify-center rounded-full font-sora font-semibold text-xs tracking-normal leading-6 text-white cursor-pointer"
              onClick={() => handleClick(index, item.path)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave()}
              style={{
                width: "75px",
                height: "34px",
                borderColor:
                  activeIndex === index ? "rgb(128, 253, 118)" : "transparent",
                borderWidth: activeIndex === index ? 1 : 0,
              }}
            >
              <div className="relative h-full w-full overflow-hidden">
                <span
                  className="absolute top-1/2 left-1/2 transform transition-all duration-300"
                  style={{
                    transform:
                      activeIndex === index
                        ? "translate(-50%, -150%)"
                        : "translate(-50%, -50%)",
                    opacity: activeIndex === index ? 0 : 1,
                  }}
                >
                  {item.name}
                </span>
                <span
                  className="absolute top-1/2 left-1/2 transform transition-all duration-300"
                  style={{
                    transform:
                      activeIndex === index
                        ? "translate(-50%, -50%)"
                        : "translate(-50%, 150%)",
                    opacity: activeIndex === index ? 1 : 0,
                  }}
                >
                  {item.name}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div
          className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 md:hidden ${
            menuOpen ? "opacity-100" : "opacity-0 pointer-events-none z-50"
          }`}
          onClick={() => setMenuOpen(false)}
        />

        <div
          className={`fixed top-16 left-0 right-0 bottom-0 bg-black bg-opacity-95 transform transition-transform duration-300 ease-in-out md:hidden ${
            menuOpen ? "translate-y-0" : "translate-y-full"
          }`}
          style={{
            backgroundColor: "rgb(128, 253, 118, 0.05)",
            maxHeight: "calc(100vh - 4rem)",
            overflowY: "auto",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full gap-8 py-8">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`font-sora font-semibold text-4xl sm:text-5xl text-center cursor-pointer transition-colors duration-300 ${
                  activeIndex === index
                    ? "text-[#80FD76]"
                    : "text-white hover:text-[#80FD76]"
                }`}
                onClick={() => handleClick(index, item.path)}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div className="fixed z-50 bottom-10 right-8 flex gap-3 m-0">
            <a
              href="https://www.linkedin.com/in/madhusudhanreddy-k/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 2.219C0 1.2695 0.789 0.5 1.7625 0.5H22.2375C23.211 0.5 24 1.2695 24 2.219V22.781C24 23.7305 23.211 24.5 22.2375 24.5H1.7625C0.789 24.5 0 23.7305 0 22.781V2.219ZM7.4145 20.591V9.7535H3.813V20.591H7.4145ZM5.6145 8.273C6.87 8.273 7.6515 7.442 7.6515 6.401C7.629 5.3375 6.8715 4.529 5.6385 4.529C4.4055 4.529 3.6 5.339 3.6 6.401C3.6 7.442 4.3815 8.273 5.5905 8.273H5.6145ZM12.9765 20.591V14.5385C12.9765 14.2145 13.0005 13.8905 13.0965 13.6595C13.356 13.013 13.9485 12.3425 14.9445 12.3425C16.248 12.3425 16.7685 13.3355 16.7685 14.7935V20.591H20.37V14.375C20.37 11.045 18.594 9.497 16.224 9.497C14.313 9.497 13.4565 10.547 12.9765 11.2865V11.324H12.9525L12.9765 11.2865V9.7535H9.3765C9.4215 10.7705 9.3765 20.591 9.3765 20.591H12.9765Z"
                  fill="white"
                />
              </svg>
            </a>

            <a
              href="https://github.com/designdeveloper5"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0.800049C5.37 0.800049 0 6.17005 0 12.8C0 18.11 3.435 22.595 8.205 24.185C8.805 24.29 9.03 23.93 9.03 23.615C9.03 23.33 9.015 22.385 9.015 21.38C6 21.935 5.22 20.645 4.98 19.97C4.845 19.625 4.26 18.56 3.75 18.275C3.33 18.05 2.73 17.495 3.735 17.48C4.68 17.465 5.355 18.35 5.58 18.71C6.66 20.525 8.385 20.015 9.075 19.7C9.18 18.92 9.495 18.395 9.84 18.095C7.17 17.795 4.38 16.76 4.38 12.17C4.38 10.865 4.845 9.78505 5.61 8.94505C5.49 8.64505 5.07 7.41505 5.73 5.76505C5.73 5.76505 6.735 5.45005 9.03 6.99505C9.99 6.72505 11.01 6.59005 12.03 6.59005C13.05 6.59005 14.07 6.72505 15.03 6.99505C17.325 5.43505 18.33 5.76505 18.33 5.76505C18.99 7.41505 18.57 8.64505 18.45 8.94505C19.215 9.78505 19.68 10.85 19.68 12.17C19.68 16.775 16.875 17.795 14.205 18.095C14.64 18.47 15.015 19.19 15.015 20.315C15.015 21.92 15 23.21 15 23.615C15 23.93 15.225 24.305 15.825 24.185C18.2076 23.3814 20.278 21.8505 21.7446 19.8081C23.2112 17.7656 24 15.3145 24 12.8C24 6.17005 18.63 0.800049 12 0.800049Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
