import React from "react";

function HeroTitle() {
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[980px] w-full relative h-[500px] flex flex-col items-center justify-center overflow-hidden px-4">
        <div
          className="font-bueno text-white tracking-normal leading-none text-center"
          style={{
            fontSize: "clamp(30px, 5.5vw, 80px)",
          }}
        >
          <span>Hi, I'm Madhusudhan, a</span>
        </div>
        <div
          className="flex flex-wrap justify-center gap-4 font-humane font-medium text-white tracking-normal leading-none text-center"
          style={{
            fontSize: "clamp(100px, 18vw, 240px)",
          }}
        >
          <span className="text-[#80FD76]">Design</span>
          <span>Developer</span>
        </div>
      </div>
    </div>
  );
}

export default HeroTitle;
