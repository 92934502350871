import React from "react";

function GraphicDesigns({ backgroundImage }) {
  return (
    <div 
      className="w-[319px] h-[320px] bg-cover bg-center rounded-lg"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    />
  );
}

export default GraphicDesigns;
