import React from "react";
import Button from "./Button";

function ContactForm() {
  return (
    <div className="w-full md:w-[980px] flex flex-wrap justify-center md:justify-between items-center pt-14 md:pt-24 pb-10 md:pb-14 px-4 md:px-0">
      <div
        className="font-sora font-semibold text-[#ffffff] leading-tight mb-6 md:mb-0 text-center lg:text-left"
        style={{
          fontSize: "32px",
        }}
      >
        <span className="w-full text-[35px] md:text-[45px] leading-none ">
          Let’s create
          <br />
          something great
          <br />
          together...
        </span>
      </div>

      <div className="w-full md:w-auto flex justify-center">
        <Button name="Get-in-touch" path="/contact" />
      </div>
    </div>
  );
}

export default ContactForm;
