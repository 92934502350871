import React from "react";

function AboutSection() {
  return (
    <div className="flex flex-col md:flex-row border border-zinc-500 rounded-lg relative px-6 md:px-9 py-6 md:py-8 backdrop-blur-sm max-w-full overflow-hidden">
      <div className="flex flex-col gap-3 w-full md:w-[900px] text-white">
        <div
          className="font-sora font-medium text-[#80FD76]"
          style={{
            fontSize: "20px",
          }}
        >
          Design. Develop. Innovate.
        </div>

        <div
          className="font-sora font-medium text-[#ffffff]"
          style={{
            fontSize: "16px",
          }}
        >
          Hi, I'm Madhusudhan, a versatile UI/UX Designer and Front-End
          Developer!
        </div>

        <div className="flex items-start gap-3">
          <span
            className="font-bueno text-[#ffffff] tracking-widest"
            style={{
              fontSize: "18px",
            }}
          >
            1
          </span>
          <div
            className="font-sora font-light text-[#ffffff]"
            style={{
              fontSize: "14px",
            }}
          >
            My passion lies at the intersection of design and code: I excel at
            transforming creative concepts into intuitive, user-friendly
            interfaces that are both aesthetically pleasing and functionally
            robust.
          </div>
        </div>

        <div className="flex items-start gap-3">
          <span
            className="font-bueno text-[#ffffff] tracking-widest"
            style={{
              fontSize: "18px",
            }}
          >
            2
          </span>
          <div
            className="font-sora font-light text-[#ffffff]"
            style={{
              fontSize: "14px",
            }}
          >
            I work closely with clients to create visually stunning,
            performance-optimized, and user-centered products, with clean,
            scalable code for easy maintenance.
          </div>
        </div>

        <div className="flex items-start gap-3">
          <span
            className="font-bueno text-[#ffffff] tracking-widest"
            style={{
              fontSize: "18px",
              letterSpacing: "0.2rem",
            }}
          >
            3
          </span>
          <div
            className="font-sora font-light text-[#ffffff]"
            style={{
              fontSize: "14px",
            }}
          >
            Whether it’s prototyping in Figma or coding in React JS and Tailwind
            CSS, I bring a full spectrum of skills to the table, always with a
            focus on creating beautiful, functional, and impactful digital
            experiences.
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
